import { getDocumentDefinitionERMCLetterHead } from "./LetterHeadPdfMaker/getDocumentDefinitionERMCLetterHead";
import { getDocumentDefinitionUnifiLetterHead } from "./LetterHeadPdfMaker/getDocumentDefinitionUnifiLetterHead";

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
 
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { AddressInfo } from "../../shared/models/AddressInfo";
import { TemplateTypeEnum } from "../../shared/enums/templateTypeEnum";
import { EmpDetails } from "../../shared/models";
import { getDocumentDefinitionScrubLetterHead } from "./LetterHeadPdfMaker/getDocumentDefinitionScrubLetterHead";
(pdfMake as any).addVirtualFileSystem(pdfFonts);

export const downloadPdfDocument = (base64String: string, fileName: string) => {
    const downloadLink = document.createElement('a');
    const pdfString = `data:application/pdf;base64,${base64String}`;

    downloadLink.href = pdfString;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink?.parentNode?.removeChild(downloadLink);
}


export const getPdfData = async (fileName: string, empDetails: EmpDetails, recipientInfo: AddressInfo, checked: boolean, verify: string): Promise<{ base64: string, blob: Blob }> => {
    let documentDefinition: TDocumentDefinitions;
    const templateName = empDetails?.templateCode;

    switch (templateName) {
        case TemplateTypeEnum.Ermc:
            documentDefinition = await getDocumentDefinitionERMCLetterHead(empDetails, recipientInfo, checked, verify);
            break;
        case TemplateTypeEnum.Unifi:
            documentDefinition = await getDocumentDefinitionUnifiLetterHead(empDetails, recipientInfo, checked, verify);
            break;
        case TemplateTypeEnum.Scrub:
            documentDefinition = await getDocumentDefinitionScrubLetterHead(empDetails, recipientInfo, checked, verify);
            break;
        default:
            throw new Error("Invalid entity code");
    }

    // Convert the PDF document definition to a Base64 string
    return new Promise<{ base64: string, blob: Blob }>((resolve, reject) => {
        const pdfDoc = pdfMake.createPdf(documentDefinition);
        
        // Get Base64
        pdfDoc.getBase64((base64: string) => {
            // Get Blob
            const blob = base64ToBlob(base64, 'application/pdf');
            resolve({ base64, blob });
        });
    });
};

function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
}