import { appSettings } from '..';
import BaseApiClient from './BaseApi';
import { EmailRequest } from '../shared/models/EmailRequest';


export class NotificationApi extends BaseApiClient {

    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        const { insiderApi } = appSettings;
        super(insiderApi.insider_api_host, authenticated);
    }

    public SendEmail(emailRequest: EmailRequest, access_token: any, access_key: any): Promise<any> {
const formData = new FormData();
    
            // Append the pdfFileBlob (direct Blob) to FormData if it exists
            if (emailRequest.pdfFileBlob) {
                formData.append('file', emailRequest.pdfFileBlob, emailRequest.fileStreamNameWithExtension || 'file.pdf');
            }
    
            // Append other fields from the emailRequest (non-file fields)
            formData.append('emailBody', emailRequest.emailBody || '');
            formData.append('emailTo', emailRequest.emailTo || '');
            formData.append('emailCc', emailRequest.emailCc || '');
            formData.append('isHighPriority', emailRequest.isHighPriority ? 'true' : 'false');
            formData.append('letterType', emailRequest.letterType || '');
    
            // Append AuditTrailBaseRequest fields
            formData.append('employeeNumber', emailRequest.employeeNumber || '');
            formData.append('requestFor', emailRequest.requestFor || '');
            formData.append('isHourlyPayRateSelected', emailRequest.isHourlyPayRateSelected ? 'true' : 'false');
            formData.append('operationType', emailRequest.operationType || '');
            formData.append('documentType', emailRequest.documentType || '');
    
            // Make the POST request with FormData
            return this._postFile<any>(this.baseUrl + '/Notification/SendMail', formData, NotificationApi.scopes, undefined, access_token, access_key);
    }    
}
