import { EmpDetails } from '../../shared/models';
import { EmpDetailsAction } from './actions';
import { EmpDetailsActionTypes } from './types';

export interface EmpDataState {
    dataResponses: Array<EmpDetails>;
}

const initialState: EmpDataState = {
    dataResponses: []
};

export const empDetailReducer = (state = initialState, action: EmpDetailsAction): EmpDataState => {
    switch (action.type) {
        case EmpDetailsActionTypes.GET_DATA_SUCCESS: {
            const {
                employeeName,
                seniorityDate,
                employmentStatus,
                jobTitle,
                hourlyPayRate,
                locationCode,
                addressLine1,
                addressLine2,
                city,
                state: responseState, // Renaming to avoid conflict with state variable
                country,
                zipCode,
                employeeNumber,
                emailAddress,
                entityCode,
                entityName,
                stationCode,
                stationDesc,
                workState,
                templateCode,
                employmentType,
                flsatype,
                annualSalary,
                county,
                salaryType
            } = action.payload.response || {};

            return {
                ...state,
                ...initialState,
                dataResponses: [{
                    employeeName,
                    seniorityDate,
                    employmentStatus,
                    jobTitle,
                    hourlyPayRate,
                    locationCode,
                    addressLine1,
                    addressLine2,
                    city,
                    state: responseState, // Using the renamed variable
                    country,
                    zipCode,
                    employeeNumber,
                    emailAddress,
                    entityCode,
                    entityName,
                    stationCode,
                    stationDesc,
                    workState,
                    templateCode,
                    employmentType,
                    flsatype,
                    annualSalary,
                    county,
                    salaryType
                }]
            };
        }

        default:
            return state;
    }
};
