import React, { useState } from 'react';
import './documentPage.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuditTrailBaseRequest, EmailRequest, EmpDetails } from '../../shared/models';
import { NavLink } from 'react-router-dom';
import routePaths from '../../shared/constants/routePaths';
import { downloadPdfDocument, getPdfData } from './generatePdfDocument';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import PdfViewerPageSection from './PdfViewerPageSection/PdfViewerPageSection';
import { OperationTypeEnum } from '../../shared/enums/OperationTypeEnum';
import { AddressInfo } from '../../shared/models/AddressInfo';

interface PdfTemplateProps {
  generateUrl?: string;
  docTitle?: string;
  verify?: string;
  rateOfPay?: boolean;
  empDetails?: EmpDetails;
  sendEmail?: (emailRequest: EmailRequest) => any;
  setDownloadAuditTrail?: (emailRequest: AuditTrailBaseRequest) => any;
}

const PdfTemplate: React.FC<PdfTemplateProps> = ({
  docTitle,
  verify,
  rateOfPay = false,
  empDetails,
  generateUrl,
  sendEmail,
  setDownloadAuditTrail
}) => {
  const toRecipientDefault = 'whom it may concern';
  const [checked, setChecked] = useState<boolean>(rateOfPay);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(empDetails?.emailAddress || '');
  const [base64String, setBase64String] = useState('');
  const [currentToRecipient, setCurrentToRecipient] = useState('');
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isSentEmailLoading, setIsSentEmailLoading] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [pdfFileBlob, setPdfFileBlob] = useState(undefined);

  const generatePdf = async () => {
    try {
      setEmail((empDetails?.emailAddress || ''));
      setIsSentEmailLoading(true);
      const fileName = `${docTitle}_${empDetails?.employeeNumber}.pdf`;
      // Assuming generatePdfDocument is an async function
      await downloadPdfDocument(base64String, fileName);

      const request = {
        // audit Trail details
        employeeNumber: empDetails.employeeNumber,
        requestFor: currentToRecipient,
        isHourlyPayRateSelected: checked,
        operationType: OperationTypeEnum.Downloaded.toString(),
        documentType: `${verify} Proof`
      };
      // Assuming setDownloadAuditTrail is an async function
      await setDownloadAuditTrail(request);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsSentEmailLoading(false);
    }
  };

  const renderPdfDocument = async (recipientInfo: AddressInfo, isChecked: boolean) => {
    setIsPreviewLoading(true);
    setChecked(isChecked);
    handleEmailChange(({ target: { value: empDetails?.emailAddress } } as React.ChangeEvent<HTMLInputElement>));
    const currentToRecipient = recipientInfo.recipient || toRecipientDefault;
    recipientInfo.recipient = currentToRecipient;
    const fileName = `${docTitle}_${empDetails?.employeeNumber}.pdf`;
    setCurrentToRecipient(getFullAddress(recipientInfo));
    getPdfData(fileName, empDetails, recipientInfo, isChecked, verify)
    .then(({ base64, blob }) => {
      setBase64String(base64);
      setPdfFileBlob(blob);
    })
    .catch(error => {
      console.error("Error generating PDF:", error);
    })
    .finally(() => {
      setIsPreviewLoading(false);
    });  
  };

  const getFullAddress = (addressInfo: AddressInfo): string => {
    const { recipient, address, city, county, state, country, postalCode, includeAddress } = addressInfo;

    if (!includeAddress) {
      return `${recipient}`;
    }

    // Format the address components. Empty strings will be ignored.
    const addressComponents = [
      `${address}\n`,
      `${city}, ${county}\n`,
      `${state}, ${country}\n`,
      postalCode
    ].filter(component => component).join(", ");

    return `${recipient}\n${addressComponents}`;
  };

  const sendPdf = async () => {
    setIsSentEmailLoading(true);
    const fileName = `${docTitle}_${empDetails?.employeeNumber}.pdf`;
    const emailRequest: EmailRequest = {
      fileStreamNameWithExtension: fileName,
      emailBody: '',
      letterType: verify,
      pdfFileBlob: pdfFileBlob,
      emailTo: email,
      emailCc: '',
      isHighPriority: false,
      // audit Trail details
      employeeNumber: empDetails.employeeNumber,
      requestFor: currentToRecipient,
      isHourlyPayRateSelected: checked,
      operationType: OperationTypeEnum.SendToMail.toString(),
      documentType: `${verify} Proof`
    };
    sendEmail(emailRequest).then(result => {
      setIsSentEmailLoading(false);
      setTimeout(() => {
        setShowPopup(false);
      }, 10);
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const PdfViewerComponent = () => {
    return (
      <div>
        <PdfViewerPageSection
          entityCode={empDetails?.entityCode}
          renderPdfDocument={renderPdfDocument}
          docTitle={docTitle}
          empDetails={empDetails}
          base64String={base64String}
          isPreviewLoading={isPreviewLoading}
          generatePdf={generatePdf}
          setShowPopup={setShowPopup}
          setBase64String={setBase64String}
        />
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Send Email" icon="pi pi-check" onClick={sendPdf} autoFocus loading={isSentEmailLoading} />
        <Button label="Cancel" icon="pi pi-times" onClick={() => setShowPopup(false)} className="p-button-text" />
      </div>
    );
  };

  const renderSendEmailDialogue = () => {
    return (
      <Dialog header="Please enter your email address" visible={showPopup} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setShowPopup(false)}>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="email" className='mb-1'>Email Address</label>
            <InputText id="email" type="email" value={email} onChange={handleEmailChange} placeholder="Your Email" className={!isEmailValid ? 'p-invalid' : ''} />
            {!isEmailValid && <small className="p-error pt-1 ml-1">Please Enter Valid Email Address</small>}
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <div className='row justify-content-center mx-0'>
      <div className="col-12 dashboard-app-nav-bar pl-4 d-flex flex-md-row">
        <NavLink to={routePaths.root}>Home</NavLink>
        <span className='d-none d-md-inline px-2'>{'>'}</span>
        <span className='d-block d-md-none px-2'>{'>'}</span>
        <span>{generateUrl}</span>
      </div>
      <div className='py-0'>
        <div className='p-2'>
          <div className='disclaimer-bar col-12'>
            Disclaimer: For any updates if the details are not populating correctly, kindly make the updates on UKG and it will take 24 hours for the system to sync in and you can generate the letter after 24 hours.
          </div>
        </div>
        {PdfViewerComponent()}
        {renderSendEmailDialogue()}
      </div>
    </div>
  );
};

export default PdfTemplate;