import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { convertImageUrlToBase64, getDocumentDefinitionContext } from '../getDoumentDefinitionContent';

import unifiHeaderBar from '../../../assets/letterheads/unifi_header_bar.png';
import unifiFooterBar from '../../../assets/letterheads/unifi_footer_bar.png';
import unifiLogoImgUrl from '../../../assets/letterheads/unifi_logo.png';
import { AddressInfo } from '../../../shared/models/AddressInfo';


// Function to generate the document definition
export const getDocumentDefinitionUnifiLetterHead = async (empDetails: any, recipientInfo: AddressInfo, checked: boolean, verify: string): Promise<TDocumentDefinitions> => {
    const base64Image = await convertImageUrlToBase64(unifiLogoImgUrl);
    const base64ImageHeader = await convertImageUrlToBase64(unifiHeaderBar);
    const base64ImageFooter = await convertImageUrlToBase64(unifiFooterBar);
    return {
        // Page size and margins
        pageSize: 'A4',
        pageMargins: [60, 35, 60, 88], // Left, top, right, bottom margins

        // Dynamic header
        header: (currentPage, pageCount, pageSize) => {
            return {
                image: base64ImageHeader,
                width: pageSize.width
            };
        },

        // Dynamic footer
        footer: (currentPage, pageCount, pageSize) => {
            // Create the array for content with proper typings
            const footerContent = [];

            // Define the text object with the correct properties
            const textObject = {
                text: 'Unifi, 950 E Paces Ferry, Atlanta, GA 30326',
                bold: false,
                fontSize: 10,
                alignment: 'center',
                marginBottom: 5,
                color: '#b20420'
            };
            footerContent.push(textObject);

            // Define the image object if base64ImageFooter is provided
            if (base64ImageFooter) {
                const imageObject = {
                    image: base64ImageFooter, // Ensure this is a correct base64 encoded string
                    width: pageSize.width,
                    alignment: 'center', // You may need to specify an alignment for the image
                };
                footerContent.push(imageObject);
            }

            return [footerContent];
        },

        // Content structure
        content: [
            //logo
            {
                image: base64Image, // Ensure this is a correct base64 encoded string
                width: 50,
                alignment: 'left',
                margin: [0, 20, 0, 20], // Adjust margins to position correctly
            },
            // Main content
            ...getDocumentDefinitionContext(empDetails, recipientInfo, verify, checked)
        ],
        defaultStyle: {
            font: 'Roboto' // Set Calibri as the default font
        },
        // Styles used in the document
        styles: {
            contentStyle: {
                fontSize: 12
            }
        },
    };
};

